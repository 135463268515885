import React from 'react';
import { useParams } from 'react-router-dom';

function StaticPage() {
  const { pageName } = useParams();

  // Construir la URL del archivo .html basado en el parámetro de la ruta
  const htmlPath = `../static/${pageName}.html`;

  return (
    <div style={{ width: '100%', height: '80vh', border: 'none', overflow: 'hidden' }}>
      <iframe
        src={htmlPath}
        title={pageName}
        style={{ width: '100%', height: '100%', border: 'none' }} 
      />
    </div>
  );
}

export default StaticPage;
