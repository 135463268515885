const pagesData = {
  Laparoscopia: {
    title: "Laparoscopía",
    description: "Información médica sobre diagnostico y tratamiento de enfermedades relacionados con Gastroenterologia, Cirugia General, y Cirugia Laparoscopica. En ella encontraras información sobre enfermedades como Colitis, gastritis, reflujo, hernia hiatal, colecistitis y otras enfermedades relacionadas con la gastroenterología.",
    keywords: "Inicio, Página principal Gastroenterología, Gastroenterologia, Cirugia General, Cirugia Laparoscopica",
    canonical: "https://unidad-gastroenterologia.com/Laparoscopia",
    // image: "/assets/images/Unidades/laparoscopia-banner.webp",
    sections: [
      {
        titleSection: "Laparoscopía",
        content: ["Es un procedimiento que permite que el cirujano visualice los órganos del abdomen, como intestinos, estómago, apéndice y vesícula biliar, y en mujeres los órganos pélvicos, como los ovarios, útero y en algunos casos practique una intervención quirúrgica para reparación (cistocele) o extirpación en dichos órganos (apendicectomía).",
          "La Cirugía Laparoscópica o Laparoscopia es un tipo de cirugía en la que en lugar de hacer una incisión para exponer la cavidad abdominal se inserta un tubo fino y largo en el abdomen que permite visualizar los órganos abdominales con una cámara de vídeo.",
          "Habitualmente, la cirugía laparoscópica da lugar a una estancia hospitalaria más breve que la tradicional. Dado que las incisiones relacionadas con la laparoscopia son muy pequeñas, el dolor que se asocia con el procedimiento es menor y la recuperación más rápida."
        ]
      },
      {
        titleSection: "¿Cómo se realiza la laparoscopía?",
        content: ["Se practica una pequeña incisión, que suele ser de 0.5 a 1.2 cm. A través de la incisión se inyecta gas que sirve para insuflar el abdomen de modo que el cirujano disponga de espacio en el que pueda trabajar. Se inserta el laparoscopio y el cirujano visualiza los órganos a través del monitor de vídeo",
          "El cirujano efectuará pequeñas incisiones adicionales o puertos con el objetivo de poder utilizar otros instrumentos para mover los órganos y visualizarlos mejor llevando a cabo cualquier procedimiento. Cuando termina la cirugía, se extraen el laparoscopio y cualquier otro instrumento y las incisiones se cierran con puntos de sutura."
        ],

        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/laparoscopia-1.gif"
      }
      // Añadir más secciones según sea necesario
    ]
  },
  Precios: {
    title: "Precios",
    description: "Información médica sobre diagnostico y tratamiento de enfermedades relacionados con Gastroenterologia, Cirugia General, y Cirugia Laparoscopica. En ella encontraras información sobre enfermedades como Colitis, gastritis, reflujo, hernia hiatal, colecistitis y otras enfermedades relacionadas con la gastroenterología.",
    keywords: "Inicio, Página principal Gastroenterología, Gastroenterologia, Cirugia General, Cirugia Laparoscopica",
    canonical: "https://unidad-gastroenterologia.com/Laparoscopia",
    // image: "/assets/images/Unidades/laparoscopia-banner.webp",
    sections: [
      {
        titleSection: "Laparoscopía",
        content: ["Es un procedimiento que permite que el cirujano visualice los órganos del abdomen, como intestinos, estómago, apéndice y vesícula biliar, y en mujeres los órganos pélvicos, como los ovarios, útero y en algunos casos practique una intervención quirúrgica para reparación (cistocele) o extirpación en dichos órganos (apendicectomía).",
          "La Cirugía Laparoscópica o Laparoscopia es un tipo de cirugía en la que en lugar de hacer una incisión para exponer la cavidad abdominal se inserta un tubo fino y largo en el abdomen que permite visualizar los órganos abdominales con una cámara de vídeo.",
          "Habitualmente, la cirugía laparoscópica da lugar a una estancia hospitalaria más breve que la tradicional. Dado que las incisiones relacionadas con la laparoscopia son muy pequeñas, el dolor que se asocia con el procedimiento es menor y la recuperación más rápida."
        ]
      },
      {
        titleSection: "¿Cómo se realiza la laparoscopía?",
        content: ["Se practica una pequeña incisión, que suele ser de 0.5 a 1.2 cm. A través de la incisión se inyecta gas que sirve para insuflar el abdomen de modo que el cirujano disponga de espacio en el que pueda trabajar. Se inserta el laparoscopio y el cirujano visualiza los órganos a través del monitor de vídeo",
          "El cirujano efectuará pequeñas incisiones adicionales o puertos con el objetivo de poder utilizar otros instrumentos para mover los órganos y visualizarlos mejor llevando a cabo cualquier procedimiento. Cuando termina la cirugía, se extraen el laparoscopio y cualquier otro instrumento y las incisiones se cierran con puntos de sutura."
        ],

        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/laparoscopia-1.gif"
      }
      // Añadir más secciones según sea necesario
    ]
  },
  Endoscopia: {
    title: "Clínica de Endoscopia Digestiva",
    description: "Pagina de informacion medica sobre el procedimiento de endoscopia, las utilidades diagnosticas en gastritis, ulcera gastrica o peptica, hernia hiatal y reflujo y utilidades terapeuticas en sangrados digestivos, cuerpos extraños, y las toma de biopsia en caso de cancer gastrico",
    keywords: "Unidad de Endoscopia Digestiva, Endoscopia Digestiva, Gastroenterologia, Cirugia General, Cirugia Laparoscopica",
    canonical: "https://unidad-gastroenterologia.com/Ebdiscopia-digestiva",
  },
  Coloproctologia: {
    title: "Coloproctología",
    description: "Información médica sobre diagnóstico y tratamiento de enfermedades relacionadas con Coloproctología, Cirugia General, y Cirugia Laparoscopica. En ella encontraras información sobre enfermedades como Colitis, gastritis, reflujo, hernia hiatal, colecistitis y otras enfermedades relacionadas con la gastroenterología.",
    keywords: "Inicio, Página principal Coloproctología, Coloproctología, Cirugia General, Cirugia Laparoscopica",
    canonical: "https://unidad-gastroenterologia.com/Clinica-de-Coloproctologia",
  },
  Colecistectomia: {
    title: "Colecistectomía",
    description: "Procedimiento quirúrgico para extirpar la vesícula biliar...",
    keywords: "colecistectomía, cirugía, vesícula biliar",
    canonical: "https://unidad-gastroenterologia.com/colecistectomia",
    image: "/assets/images/Unidades/colecistectomia-banner.webp",
    sections: [
      {
        titleSection: "COLECISTECTOMIA LAPAROSCÓPICA",
        content: [
          "La preparación preoperatoria para la colecistectomía laparoscópica es similar a la colecistectomía abierta, básicamente se requiere de ayuno de por lo menos 6 horas.",
        ],
        // image: "/assets/images/Unidades/funduplicatura.webp"
      },
      {
        titleSection: "Posición para Colecistectomia Laparoscópica",
        content: [
          "Se coloca al paciente en posición acostado boca arriba, y bajo anestesia general con intubación endotraqueal. Para evitar posibles lesiones inadvertidas en el estómago o la vejiga, a veces se coloca una sonda naso gástrica y un catéter vesical.",
          "El paciente está preparado y cubierto en la habitual forma estéril. Tubo de insuflación, los cables de la cámara, y el tubo de succión están garantizados. La cámara laparoscópica es a color.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/trocares-colecistectomia.gif"
      },
      {
        titleSection: "Incisión y establecimiento del Neumoperitoneo",
        content: ["Se hace una incisión en la piel en forma transversal por encima del ombligo, y con disección roma se llega a la línea alba. Con dos pinzas de piel el cirujano levanta la piel y la pared abdominal e inserta la aguja de Veress para insuflación de gas o directamente el trocar de 10 mm a través de la fascia en la cavidad peritoneal.",
          "La punta de la aguja de Veress debe ser dirigida hacia los pies del paciente para evitar la lesión inadvertida de la aorta abdominal.",
          "Para confirmar la presencia de la aguja dentro de la cavidad abdominal, se realiza una prueba de caída con una gota de agua: mientras la pared abdominal se levanta con las pinzas, una gota de solución salina se coloca en el centro de la aguja. La gota de solución salina debe caer rápidamente si la punta de la aguja de Veress está dentro de la cavidad peritoneal.",
          "Se inicia la insuflación abdominal, y la lectura de la presión inicial debe seguir siendo relativamente baja. Presiones de alta durante el inicio de la insuflación indican que la aguja probablemente no está bien colocado y debe ser reubicada o reinsertada."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Colocación de trocares para la Colecistectomia Laparoscopica",
        content: ["Un puerto de 10-mm, se coloca a través de la incisión transversal en ombligo. La cámara de vídeo se transmite a través de la cánula para realizar un examen cuidadoso para confirmar su presencia dentro de la cavidad intra abdominal, para excluir algún daño involuntario, y para ver la vesícula biliar.",
          "A continuación, tres puertos necesitan ser colocados a lo largo de la línea media, línea medio clavicular y la línea axilar anterior a lo largo de una línea recta imaginaria que es equivalente a la incisión tradicional de la cirugía abierta.",
          "La ubicación exacta de estos puertos, sin embargo, puede ser determinada por empujar la pared abdominal en el exterior y luego de confirmar que esta sería la ubicación adecuada de visualización con la cámara de vídeo.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica.gif"
      },
      {
        titleSection: "Técnica de Colecistectomia Laparoscopica",
        content: [
          "Bajo visión directa, primero un puerto de 10-mm, se coloca en la línea media de aproximadamente 5 a 6 cm por debajo del apéndice xifoides. A continuación, otro puerto de 5-mm se coloca en la línea clavicular media y en la línea axilar anterior.",
          "Uno de los fundamentos de la seguridad en la cirugía laparoscópica es que cada vez que un instrumento es pasado a través del puerto debe ser bajo visión directa, los instrumentos no debe ser insertado a ciegas, para evitar una lesión intra-abdominal.",
          "Antes que comience la disección, el paciente se coloca en la posición de Trendelenburg y una pinza pasa a través del puerto más lateral para tomar el fondo de la vesícula biliar y llevarla hacia el hombro derecho.",
          "Otra pinza se pasa a través del puerto medio clavicular para tomar el infundíbulo o cuello de la vesícula biliar, y se retraen lateralmente para colocar el conducto cístico en tensión.",
          "El revestimiento peritoneal en el triángulo de Calot es cuidadosamente disecado usando la tijera o el gancho laparoscopico. Se limpia el tejido graso de este lugar para poder identificar el conducto cístico. Con un disector de ángulo recto se diseca el conducto cístico y también su unión con el conducto biliar común el cual debe ser claramente visualizado. Es importante asegurarse de que la unión de la vesícula biliar con el conducto cístico para evitar una lesión inadvertida del conducto hepático derecho.",
          "Si es difícil identificar el conducto cístico se debe disecar el peritoneo de la vesícula biliar, el peritoneo que cubre a cada lado el lecho de la vesícula biliar para ser cuidadosamente dividida.",
          "Si la unión del conducto cístico con la vesícula biliar no está todavía identificada con seguridad, el procedimiento debe ser convertido a una colecistectomía abierta.",
          "La conversión a un procedimiento abierto no debería ser considerada una derrota, sino una decisión prudente por parte del cirujano.",

        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica-2.gif"
      },
      {
        content: [
          "Una vez identificado el conducto cístico se aplican dos clips proximales y dos clips distales y cortar conducto cístico. Con más disección roma y cortante en el triángulo de Calot, se identifica la arteria cística y se remontan a la pared de la vesícula biliar. Una vez bien identificada la arteria cística, es ligada con hemoclips y dividida.",
          "A continuación, se diseca la vesícula biliar de su lecho hepático. Para lograr esto, el infundíbulo de la vesícula biliar es captado junto a la hemoclips. Mientras que la vesícula biliar se gira de lado a lado, la cubierta peritoneal y el tejido areolar se dividen con el gancho del electro bisturí. A medida que la disección se acerca al fondo de la vesícula biliar, se reduce la retracción cefálica.",
          "El infundíbulo se retrae, la cama de la vesícula biliar es inspeccionada, y la hemostasia se completa.",
          "Una vez que la vesícula biliar es completamente libre de su lecho hepático, la cámara se transfiere al puerto que está en la parte superior del abdomen y con una bolsa laparoscópica que se introduce por el puerto umbilical se retira la vesícula biliar.",
          "La incisión umbilical puede ser necesario extenderse si hay dificultad para extracción de la vesícula biliar distendida por piedras grandes o inflamación importante de su pared. Cualquier derrame de bilis o de sangre es aspirado. Si se va colocar un drenaje, este se exterioriza a través del puerto más lateral y se dirige hacia la bolsa de Morison subhepático bajo visión directa."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica-3.gif"
      },
      {
        titleSection: "Cierre de la Pared Posterior a Colecistectomia",
        content: ["Los defectos de la fascia o apreturas de los puertos, en la región peri umbilical e infra xifoidea se cierran con suturas absorbibles. La piel se aproxima con puntos subdermicos. Una vez que el paciente está despierto en la sala de recuperación, la sonda naso gástrica y el catéter vesical puede ser retirados.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/colecistectomia-laparoscopica-5.jpg"
      },
    ]
  },
  Funduplicatura: {
    title: "Funduplicatura",
    description: "Procedimiento para tratar el reflujo gastroesofágico...",
    keywords: "funduplicatura, cirugía, reflujo gastroesofágico",
    canonical: "https://unidad-gastroenterologia.com/funduplicatura",
    image: "/assets/images/Unidades/funduplicatura-banner.webp",
    sections: [
      {
        titleSection: "Funduplicatura",
        content: [
          "La funduplicatura es un procedimiento quirúrgico utilizado para tratar el reflujo gastroesofágico (ERGE). Este procedimiento fortalece la válvula entre el esófago y el estómago.",
        ],
        // image: "/assets/images/Unidades/funduplicatura.webp"
      },
      {
        titleSection: "Preparación Preoperatoria",
        content: [
          "Antes de tomar la decisión de realizar un procedimiento anti reflujo, la presencia de reflujo gastro-esofágico con todas las complicaciones que acompañan, como la esofagitis por reflujo o la formación de estenosis debe ser confirmada.",
          "La deglución de bario preoperatorio debe ser revisada para decartar esófago corto, estenosis esofágica.",
          "El vaciamiento gástrico y la pHmetria de 24 horas deben ser monitoreadas para correlación de dolor del paciente con episodios de reflujo gástrico.",
          "Debido al uso de medicamentos como la ranitidina o el omeprazol la aclorhidria gástrica puede permitir el sobrecrecimiento bacteriano, y por lo tanto los antibióticos peri operatorios deben ser administrados."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica.gif"
      },
      {
        titleSection: "Posición",
        content: ["El paciente se coloca en la posición de litotomía para permitir al cirujano operar entre las piernas del paciente. Esto permite un cómodo acceso al hiato esofágico."],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Insición y Establecimiento del Neumoperitoneo",
        content: ["La insición inicial es transversal y en el pliegue que se hace por encima del ombligo, se diseca en forma roma el tejido subcutáneo hasta encontrar la línea alba.",
          "Se toma la piel con dos pinzas de campo y se la levanta para insertar la aguja de Veress través de la fascia hasta la cavidad peritoneal.",
          "La punta de la aguja de Veress debe ser dirigida hacia los pies del paciente para evitar la lesión inadvertida de la aorta abdominal.",
          "Se inicia la insuflación Intra abdominal cosa que la cavidad abdominal se distiende uniformemente dando un sonido timpánico a la percusión. La presión intra-abdominal debe tener un aumento gradual. Una vez que la presión intra-abdominal ha alcanzado alrededor de 15 mmHg y se ha introducido 2,5 a 3 litros de CO2 se retira la aguja de Veress."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Colocación de Puertos",
        content: ["Cuatro puertos de exploración se sitúan a lo largo de un semicírculo frente al apéndice xifoides. El ayudante en el lado derecho del paciente opera la cámara. El paciente se coloca en una posición de Trendelenburg."],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-2.gif"
      },
      {
        titleSection: "Técnica de la Funduplicatura Laparoscópica",
        content: ["Después de la colocación de todos los puertos, se hace una abertura en el ligamento gastrohepático y se extiende por el esófago intra-abdominal. La disección de todo el hiato esofágico es similar a la del procedimiento abierto.",
          "Los dos pilares son claramente identificados, y la parte inferior de 3 a 4 cm. del esófago abdominal es movilizado. El esófago se retrae a la derecha, y el defecto hiatal se cierra con suturas de seda, prolene o ethibon. Para evitar la tensión excesiva del cierre se puede colocar una bujía esofágica de 60 P. Maloney.",
          "A continuación, con la ayuda de un bisturí armónico, se diseca los vasos cortos en la parte proximal de la curvatura mayor para poder liberarla.",
          "Un drenaje de Penrose se coloca alrededor del esófago abdominal para elevarlo hacia la pared abdominal anterior. Esto abre el espacio retroesofágica, mediante el cual el asistente puede captar el fondo de ojo previamente liberado y pasarlo por debajo y al lado izquierdo del esófago.",
          "Por último, el envoltorio del fondo, de aproximadamente 2 cm., se sutura de modo que queda fondo-esófago-fondo gástrico de una manera similar a la técnica abierta.",
          "Una vez finalizado el procedimiento, la zona del hiato esofágico es irrigada con solución salina. Los sitios del puerto se retiran bajo visión directa."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-3.gif"
      },
      {
        titleSection: "Cierre",
        content: ["Los defectos de la aponeurosis se cierran con puntos en cruz o en ocho con sutura 0-0 absorbibles y la piel se cierra con sub-dérmico de dermalon 3/0.",
        ]
      }
    ]
  },
  Apendicectomia: {
    title: "Apendicectomia",
    description: "Procedimiento para tratar el reflujo gastroesofágico...",
    keywords: "funduplicatura, cirugía, reflujo gastroesofágico",
    canonical: "https://unidad-gastroenterologia.com/funduplicatura",
    image: "/assets/images/Unidades/funduplicatura-banner.webp",
    sections: [
      {
        titleSection: "Apendicectomía",
        content: [
          "La apendicectomía es una técnica quirúrgica utilizada para extraer el apéndice, un pequeño tubo con forma de dedo que se encuentra entre el intestino delgado y el grueso.",
          "Se realiza principalmente en casos de apendicitis aguda, cuando el apéndice está inflamado o infectado. La cirugía se lleva a cabo bajo anestesia general y puede realizarse de dos maneras:",
          "Apendicectomía abierta: Se hace una incisión en el lado derecho del abdomen y se extirpa el apéndice.",
          "Apendicectomía laparoscópica: Se realizan tres o cuatro pequeñas incisiones en el abdomen para quitar el apéndice de forma menos invasiva.",
        ],
      },
      {
        titleSection: "Preparación Preoperatoria",
        content: [
          "Una vez que se determina el diagnóstico de la apendicitis aguda y se toma la decisión de explorar al paciente, es imperativo hidratar al paciente de forma adecuada con líquidos intravenosos. Se debe administrar antibióticos por vía intravenosa.",
          "En los pacientes jóvenes, los estudios de gabinete son mínimos cuando el cuadro clínico es muy sospechoso de apendicitis.",
          "Si la masa es palpable en el cuadrante inferior derecho, la tomografía computarizada de abdomen y de pelvis puede ser valiosa para evaluar la presencia de absceso apendicular o bien un tumor maligno que se origina desde el ciego, el apéndice o el intestino delgado.",
          "Dependiendo de la experiencia del radiólogo, a menudo un ultrasonido puede ser utilizado para la visualización del apéndice engrosado"
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica.gif"
      },
      {
        titleSection: "Establecimiento de Neumoperitoneo",
        content: ["La insuflación intra-abdominal de CO2 es establecida en la forma habitual a través del puerto peri umbilical. El paciente se coloca en la posición de Trendelenburg para mejorar la visualización de la pelvis y el abdomen inferior.",
          "Por norma se realiza de examen de diagnóstico laparoscópico de toda la cavidad abdominal."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Colocación de los Puertos",
        content: ["Dos puertos están colocados, un puerto de 10-mm en la zona suprapúbica y un puerto de 5 mm en el cuadrante inferior izquierdo. Ambos están dirigidos hacia el apéndice."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica-1.gif"
      },
      {
        titleSection: "Técnica Quirúrgica",
        content: ["Con una técnica de dos manos, el cirujano expone el apéndice con el uso de una pinza atraumática. Una vez localizado, la punta del apéndice se toma con una pinza atraumática y se la levanta, hacia la pared abdominal.",
        "El meso apéndice se diseca cerca de la base del apéndice con una pinza curva y se liga, se engrapa o se corta con bisturí armónico.",
        "La base del apéndice se libera cuidadosamente de cualquier tejido adiposo que lo rodee y se seccionado en su base con engrapadota, corte y endolup o ligadura transfictiva endocorporal.",
        "El apéndice extirpado se coloca dentro de una bolsa de extracción.",
        "El cuadrante inferior derecho se riega con solución salina para eliminar cualquier material contaminado.",
        "Si se encuentra alguna dificultad durante la disección, el procedimiento debe ser convertido a una apendicetomía abierta.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica-2.gif"
      },
      {
        titleSection: "Cierre",
        content: ["Los defectos de la aponeurosis se cierran con puntos en cruz o en ocho con sutura 0-0 absorbibles y la piel se cierra con sub-dérmico de dermalon 3/0.",
        ]
      }
    ]
  },
  // Añadir más páginas aquí
};

export default pagesData;
