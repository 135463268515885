import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../assets/css/UnidadesPages/styles.css';

const LaparoscopiaPage = ({ pageData }) => {
  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <div>
        <h4 className='m-4'>Selecciona una página:</h4>
        <div className='d-flex flex-column flex-md-row justify-content-center'>
          <Link to="/Laparoscopia" className={`button-menu-doctor ${pageData.title === 'Laparoscopía' ? 'active' : ''}`}>Laparoscopía</Link>
          <Link to="/Laparoscopia/Colecistectomia" className={`button-menu-doctor ${pageData.title === 'Colecistectomía' ? 'active' : ''}`}>Colecistectomía</Link>
          <Link to="/Laparoscopia/Funduplicatura" className={`button-menu-doctor ${pageData.title === 'Funduplicatura' ? 'active' : ''}`}>Funduplicatura</Link>
          <Link to="/Laparoscopia/Apendicectomia" className={`button-menu-doctor ${pageData.title === 'Apendicectomía' ? 'active' : ''}`}>Apendicectomía</Link>
        </div>
      </div>
      <section style={{ backgroundImage: `url(${pageData.image})` }} className="unidades-section">
        <Container fluid>
          <div className="my-5">
            {pageData.sections && pageData.sections.map((section, index) => (
              <div key={index} className="">
                {index === 0 ? (
                  <h1>{section.titleSection}</h1>
                ) : (
                  <h2>{section.titleSection}</h2>
                )}
                <Row className='p-5'>
                  {section.image ? (
                    index % 2 === 0 ? (
                      <>
                        <Col md={4}>{<img src={section.image} alt={section.titleSection} className="img-fluid mb-3 rounded-5" />}</Col>
                        <Col md={8}>{section.content.map((paragraph, i) => (
                          <p key={i}>{paragraph}</p>
                        ))}</Col>
                      </>
                    ) : (
                      <>
                        <Col md={8}>{section.content.map((paragraph, i) => (
                          <p key={i}>{paragraph}</p>
                        ))}</Col>
                        <Col md={4}>{<img src={section.image} alt={section.titleSection} className="img-fluid mb-3 rounded-5" />}</Col>
                      </>
                    )
                  ) : (
                    <Col>
                      {section.content.map((paragraph, i) => (
                        <p key={i}>{paragraph}</p>
                      ))}
                    </Col>
                  )}
                </Row>
              </div>
            ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default LaparoscopiaPage;
