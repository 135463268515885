import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import HeroSection from '../components/HeroSection';
import '../assets/css/PreciosSection/styles.css';
import SectionUnidadesMedicas from '../components/SectionUnidadesMedicas.jsx';


const HomePage = ({ title, description, keywords, canonical }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link rel='canonical' href={canonical} />
    </Helmet>
    <header className="App-header">
      <HeroSection />
    </header>
    <main className="main-content py-5">
    <SectionUnidadesMedicas />
    </main>


    <Container fluid className='p-5'>
      <Row className="row gy-4">

        <Col lg={6} data-aos="fade-up" data-aos-delay="100" className='d-flex flex-column justify-content-center align-items-center content'>
          {/* <p className="who-we-are">Who We Are</p> */}
          <h3 className='text-primary h1'>Conoce nuestro equipo de expertos</h3>
          <p className="fst-italic fw-500">Con mas de 15 años de experiencia en el área de la Medicina, conociendo a los pacientes, a las enfermedades y a los profesionales de la salud, nos dedicamos a brindar la mejor atención a nuestros pacientes.</p>
          <Link to="/Doctores" className="button-equipo-medico"><span>Ver Equipo Médico</span><i className="bi bi-arrow-right"></i></Link>
        </Col>

        <Col lg={6} className="col-lg-6 about-images aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
          <Row className="row gy-4">
            <Col lg={6} className="col-lg-6">
              <img src="https://elmedicointeractivo.com/wp-content/uploads/2022/05/gremio-medico-se-defiende--e1652261255864.jpg" className="img-fluid rounded-4" alt=""></img>
            </Col>
            <Col lg={6} className="col-lg-6">
              <Row className="row gy-4">
                <Col lg={12} className="col-lg-12">
                  <img src="https://estepais.com/wp-content/uploads/2019/10/imagenes-estepais-doctores.jpg" className="img-fluid rounded-4" alt=""></img>
                </Col>
                <Col lg={12} className="col-lg-12">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJDCvGiKOVfCrELAnLAD8jea2Bl-zuxvI4ig&s" className="img-fluid rounded-4" alt=""></img>
                </Col>
              </Row>
            </Col>
          </Row>

        </Col>

      </Row>
    </Container>

  </>
);

export default HomePage;
