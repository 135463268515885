import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DoctorCard from './extras/DoctorCard';
import doctorsData from '../assets/data/doctors.json';
import '../assets/css/CardsMenu/DoctorCard.css';

const DoctorList = ({ specialtyToShow}) => {
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    let filteredDoctors = doctorsData;

    if (specialtyToShow) {
      filteredDoctors = filteredDoctors.filter(doctor => doctor.specialty.includes(specialtyToShow));
    }

    setDoctors(filteredDoctors);
  }, [specialtyToShow]);

  return (
    <Container>
      <Row className='d-flex justify-content-center'>
        {doctors.map((doctor) => (
          <Col md={6} lg={3} key={doctor.id} className={`DoctorCard d-flex justify-content-center ${doctor.group.join(' ')}`}>
            <DoctorCard
              id={doctor.id}
              name={doctor.name}
              title={doctor.title}
              specialty={doctor.specialty.join(', ')}
              photoUrl={doctor.photoUrl}
              className={doctor.specialty.join(' ')}
              group={doctor.group.join(', ')}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DoctorList;
