import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import ServiceList from '../components/ServiceList.jsx';
// import MenuCardInfo from '../components/MenuCardInfo.jsx';
import DoctorList from '../components/DoctorList';

const ColoproctologiaPage = ({ pageData }) => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section style={{ backgroundImage: `url(${pageData.image})` }} className="unidades-section">
        <Container fluid>
          <div className="my-5">
            <div className="my-5">
              <h1>Unidad de {pageData.title}</h1>
              <hr />
              <Row className='p-lg-5 d-flex align-items-center'>
                <Col className='p-lg-5' md={6}>
                  <div className="info-item d-flex align-items-center">
                    <div>
                      <p>La Coloproctología es la especialidad médica dedicada al diagnóstico y tratamiento de las enfermedades del colon, recto y ano. Antes denominada Proctología que solo veía la parte ano, actualmente un Coloproctologo debe saber conocer y tratar enfermades del colon y del recto y es por esto que para ser un coloproctologo antes debe ser un Cirujano Gastrointestinal.</p>
                    </div>
                  </div>
                </Col>
                <Col className='p-5 align-items-center' md={6}>
                  <div className="info-item d-flex justify-content-around">
                    <div>
                      <img className='img-fluid rounded-4' width={300} src="https://unidad-gastroenterologia.com/images/gastro_coloproctologia.jpg" alt="Imagen de Coloproctología" />
                    </div>
                  </div>
                </Col>
                {/* <Col>
                  <div className="info-item d-flex align-items-center">
                    <div>
                      <h2 className='p-3 p-lg-5'>Información médica sobre diagnóstico y tratamiento de enfermedades relacionadas con {pageData.title}.</h2>
                    </div>
                  </div>
                </Col> */}
              </Row>
              <div className='doctor-section-content'>
                <h2>Servicios que ofrecemos en la <br></br>Unidad de {pageData.title}:</h2>
                <h4 className='p-5'>Bienvenido a nuestra Clínica de {pageData.title}, un centro especializado en el diagnóstico, tratamiento y prevención de enfermedades del colón, recto y ano. Nos mantenemos a la vanguardia con técnicas modernas, como ligaduras con bandas elasticas, cirugía laser, colonoscopía de alta resolución para garantizar resultados optimos con tiempos de recuperación minima. Contamos con un equipo de Coloproctologos altamente capacitados.</h4>
                <div>
                  <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
                  <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
                  <button className={`button-menu-doctor ${selectedService === 'Procedimiento' ? 'active' : ''}`} onClick={() => handleServiceSelect('Procedimiento')}>Procedimiento</button>
                  <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
                </div>
                <ServiceList serviceToShow={selectedService} moduleToShow="Coloproctología" />
              </div>
              <div className='doctor-section-content'>
                <h2>Conoce a nuestro Equipo Médico:</h2>
                <h4>Contamos con un Equipo de Gastroenterólogos altamente capacitados y con experiencia en diversas Sub Especialidades, Nuestos Médicos estan comprometidos a mantenerse al dia con las ultimas investigaciones
                y técnicas en el campo de la Gastroenterológia, garantizando así tratamientos basados en evidencias y protocolos actualizados :</h4>
                <div>
                </div>
                <DoctorList specialtyToShow={'Coloproctología'} groupToShow={'Coloproctología'} />
              </div>
              <div className='Info-Card-Gastro'>
                
                <h2 className='p-5'>Información sobre sintomas y estudios de la<br></br>Unidad de {pageData.title}:</h2>
                {/* <MenuCardInfo infoToShow={'Coloproctología'}/> */}
                No hay :C
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};



export default ColoproctologiaPage;
