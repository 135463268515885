import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Card, Image} from 'react-bootstrap';
import '../assets/css/SliderUnidades/styles.css';

const SliderUnidades = () => {

  const carouselRef = useRef(null);

  const elementos = [
    {
      imagen: 'https://unidad-gastroenterologia.com/images/clinica-de-balon-gastrico-header.jpg',
      titulo: 'CLÍNICA DE ENDOSCOPÍA DIGESTIVA',
      descripcion: 'Método invasivo de diagnóstico y tratamiento intervencionista de enfermedades del Aparato Digestivo Alto (Esófago, Estomago y Duodeno) o Digestivo Bajo (Colon y Recto)',
      tipo: 'Endoscopía Digestiva',
      link: '/Dermatologia'
    },
    {
      imagen: 'https://unidad-gastroenterologia.com/images/gastro_2.jpg',
      titulo: 'Gastroenterologia',
      descripcion: 'Especialidad Médica dedicada al tratamiento de enfermedades del tubo digestivo y glándulas asociadas.',
      tipo: 'Gastroenterología',
      link: '/Gastroenterologia'
    },
    {
      imagen: 'https://unidad-gastroenterologia.com/images/gastro_9.jpg',
      titulo: 'Clínica de Coloproctología',
      descripcion: 'Clínica de coloproctología es una especialidad que se dedica a la investigación y el desarrollo de tratamientos para la coloproctología.',
      tipo: 'Clínica de Coloproctología',
      link: '/Clinica-de-Coloproctología'
    },
    {
      imagen: 'https://gastrocirugiaytrasplantescdmx.com.mx/assets/image/servicios/hernia-hiatal-y-reflujo-erge.webp',
      titulo: 'Tratamiento de Reflujo y Hernia Hiatal',
      descripcion: 'El tratamiento de reflujo y hernia hiatal es un tratamiento que se utiliza para tratar la hernia hiatal.',
      tipo: 'Tratamiento de Reflujo y Hernia Hiatal',
      link: '/Tratamiento-de-Reflujo-y-Hernia-Hiatal'
    },
    {
      imagen: 'https://gastrolapaz.com.mx/wp-content/uploads/2023/03/Cirugia-de-hernia-endohope-1.jpg',
      titulo: 'Clínica de Hernias',
      descripcion: 'La metría de pH es una medida de la concentración de agua en el aguador, y se utiliza para determinar la salud de la sangre.',
      tipo: 'Tratamiento de Hernias',
      link: 'https://clinica-hernia.com/'
    },
    {
      imagen: 'https://unidad-gastroenterologia.com/images/gastro_9.jpg',
      titulo: 'CIRUGÍA LAPAROSCÓPICA',
      descripcion: 'Procedimiento que permite que el cirujano visualice los órganos del abdomen, como intestinos, estómago, apendice y vesícula biliar, y en mujeres los órganos pélvicos.',
      tipo: 'Laparoscopía',
      link: '/Laparoscopia'
    },
    {
      imagen: 'https://unidad-gastroenterologia.com/images/gastro_7.jpg',
      titulo: 'MOTILIDAD INTESTINAL',
      descripcion: 'La motilidad intestinal es el movimiento que tiene el tubo digestivo para propulsar el bolo alimenticio de la boca hacia el ano y tiene las funciones de transportar y fragmentar los alimentos, mezclar las secreciones digestivas con el alimento para que puedan absorberse.',
      tipo: 'PHMetría y Manomería',
      link: '/MotilidadIntestinal'
    },
    {
      imagen: 'https://unidad-gastroenterologia.com/images/ultrasonido-abdominal.jpg',
      titulo: 'Ultrasonido Abdominal',
      descripcion: 'El uso de la ultrasonido abdominal es una técnica de diagnóstico que permite identificar la presencia de problemas de digestión, como la pélvico-gastrointestinal.',
      tipo: 'Ultrasonido abdominal',
      link: '/Ultrasonido-Abdominal'
    },
    {
      imagen: 'https://unidad-gastroenterologia.com/images/gastro_3.jpg',
      titulo: 'CHECK UP',
      descripcion: 'Es un examen clínico diagnóstico y preventivo de el tubo digestivo, sitio de alta frecuencia de enfermedades, sangrado y cáncer en la población mexicana.',
      tipo: 'Check up',
      link: '/Check-Up'
    },
    {
      imagen: 'https://www.canadianmanufacturing.com/wp-content/uploads/2022/11/Clinique_Michel_Gagner_A_Cutting_edge_Gastric_Balloon_Arrives_in.jpeg',
      titulo: 'CLÍNICA DE BALÓN GÁSTRICO',
      descripcion: 'Se trata de un tratamiento multidisciplinario llevado a cabo por un equipo compuesto por el cirujano gastroenterólogo, nutriólogo, psicólogo y entrenador físico que te va a permitir bajar de peso de una forma segura y sana gracias.',
      tipo: 'Balón Gastrico',
      link: 'https://balongastricocdmx.com/'
    },
    {
      imagen: 'https://www.canadianmanufacturing.com/wp-content/uploads/2022/11/Clinique_Michel_Gagner_A_Cutting_edge_Gastric_Balloon_Arrives_in.jpeg',
      titulo: 'Manga Gástrica Endoscópica',
      descripcion: 'Procedimiento para bajar de  peso y mejorar la salud de los pacientes.',
      tipo: 'Manga Gástrica Endoscópica',
      link: 'https://www.mangagastricaendoscopica.mx/'
    }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const CustomDot = ({ onClick, active, index }) => {
    const handleDotClick = () => {
      const currentSlideIndex = index * responsive[window.innerWidth >= 1024 ? 'desktop' : window.innerWidth >= 600 ? 'tablet' : 'mobile'].items;
      carouselRef.current.goToSlide(currentSlideIndex); // Navega directamente al índice correcto
    };
  
    return (
      <li
        className={`custom-dot ${active ? 'active' : ''}`}
        onClick={handleDotClick}
      >
        <img
          src={elementos[index].imagen}
          alt={`Miniatura de ${elementos[index].titulo}`}
          className="img-thumbnail"
        />
      </li>
    );
  };
  

  return (
    <div className="slider-container">
      <Carousel
        ref={carouselRef}
        arrows={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4500}
        keyBoardControl={true}
        containerClass="carousel-container-unidades"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        customDot={<CustomDot />}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        showDots={true}
        renderDotsOutside={false}
      >
        {elementos.map((elemento, index) => (
          <Link to={elemento.link} key={index} className="card-link">
            <Card className="slider-card-unidades">
              <Image loading='lazy' className='slider-imagen-unidades img-fluid' variant="top" src={elemento.imagen} alt={`Imagen de ${elemento.titulo}`} />
              <Card.Body>
                <p className="muted">{elemento.tipo}</p>
                <Card.Title className='tittle-card-unidades'>{elemento.titulo}</Card.Title>
                <Card.Text>{elemento.descripcion}</Card.Text>
              </Card.Body>
            </Card>
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderUnidades;