import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import logo from '../assets/images/logos/logomedicci-300px.svg';
import '../assets/css/navbar/styles.css';
import DarkButton from './button/dark';
import DropdownMenu from './extras/DropDown';

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const menuUnidades = [
    { label: 'Gastroenterología', to: '/Gastroenterologia' },
    { label: 'Cirugía Laparoscópica', to: '/Laparoscopia' },
    { label: 'Clínica de Coloproctología', to: '/Clinica-de-Coloproctologia' },
    { label: 'Clínica de Endoscopía Digestiva', to: '/Endoscopia-Digestiva' },
    { label: 'Clínica de Cirugía Laparoscópica', to: '/Clinica-de-Cirugia-Laparoscopica' },
    { label: 'PHmetría y Manometría', to: '/PHmetria-y-Manometria' },
    { label: 'Ultrasonido Abdominal', to: '/Ultrasonido-Abdominal' },
    { label: 'Check Up Gastroenterológico', to: '/check-up' },
    { label: 'Clínica de Hernias', to: 'https://clinica-hernia.com/',
       target: '_blank',
       rel: 'noopener noreferrer',
       ariaLabel: 'Clínica de Hernias (abre en una nueva pestaña)'
     },
    { 
      label: 'Manga Gástrica Endoscópica',
      target: '_blank',
      to: 'https://www.mangagastricaendoscopica.mx/',
      rel: 'noopener noreferrer',
      ariaLabel: 'Manga Gástrica Endoscópica (abre en una nueva pestaña)'
    },
  ];
  const menuItems = [
    { label: 'Enfermedades de Esofago', to: '/Enfermedades-de-Esofago' },
    { label: 'Enfermedades de Estomago', to: '/Enfermedades-de-Estomago' },
    { label: 'Enfermedades de Hígado y Vías Biliares', to: '/Enfermedades-de-Higado-y-Vias-Biliares' },
    { label: 'Enfermedades de Páncreas', to: '/Enfermedades-de-Pancreas' },
    { label: 'Enfermedades de Colon', to: '/Enfermedades-de-Colon' },
    { label: 'Enfermedades ANO-Rectales', to: '/Enfermedades-ANO-Rectales' },
  ];
  const menuPublicaciones = [
    { label: 'Diagnóstico y Clasificación de la Enfermedad por Reflujo Gastro-Esofágico', to: '/Diagnostico-y-Clasificacion-de-la-Enfermedad-por-Reflujo-Gastro-Esofagico' },
    { label: 'ESTREÑIMIENTO Y COLON IRRITABLE EN EL ADULTO MAYOR', to: '/ESTRENIMIENTO-Y-COLON-IRRITABLE-EN-EL-ADULTO-MAYOR' },
    { label: 'HELICOBACTER PYLORI: DIAGNÓSTICO Y MANEJO DE LA ENFERMEDAD', to: '/HELICOBACTER-PYLORI-DIAGNOSTICO-Y-MANEJO-DE-LA-ENFERMEDAD' },
    { label: 'SELECCIÓN DE PACIENTE PARA CIRUGÍA ANTIREFLUJO GASTROESOFÁGICA', to: '/SELECCION-DE-PACIENTE-PARA-CIRUGIA-ANTIREFLUJO-GASTROESOFAGICA' },
    { label: 'ABUSO CRÓNICO DE LAXANTES EN EL ADULTO MAYOR', to: '/ABUSO-CRONICO-DE-LAXANTES-EN-EL-ADULTO-MAYOR' },
    { label: 'COMPLICACIONES DE LA COLECISTITIS DE LARGA EVOLUCION', to: '/COMPLICACIONES-DE-LA-COLECISTITIS-DE-LARGA-EVOLUCION' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  const handleToggle = () => {
    setExpanded(expanded ? false : true);
  };

  return (
    <Navbar sticky="top" bg={scrolled ? "light" : "dark"} expand="lg" className={scrolled ? 'scrolled' : ''} expanded={expanded}>
      <Container>
        <Navbar.Brand as={Link} to='/' onClick={handleNavLinkClick}>
          <img
            src={logo}
            height="30"
            alt="Logo Medicci"
            className='logo'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={scrolled ? 'me-auto' : 'me-auto'}>
            <Nav.Link as={Link} to="/" className='nav-link-menu' onClick={handleNavLinkClick}>Inicio</Nav.Link>
            <DropdownMenu title="Unidades Medicas" items={menuUnidades} className='nav-link-menu' handleNavLinkClick={handleNavLinkClick} />
          </Nav>
          <Nav className={scrolled ? 'me-auto' : 'me-auto'}>
            <DropdownMenu title="Enfermedades" items={menuItems} className='nav-link-menu' handleNavLinkClick={handleNavLinkClick} />
            <DropdownMenu title="Publicaciones" items={menuPublicaciones} className='nav-link-menu' handleNavLinkClick={handleNavLinkClick} />
            <Nav.Link as={Link} to="/Doctores" className='nav-link-menu' onClick={handleNavLinkClick}>Médicos</Nav.Link>
            <Nav.Link eventKey={2} as={Link} to="/ubicaciones" className='nav-link-menu' onClick={handleNavLinkClick}>Ubicaciones</Nav.Link>
          </Nav>
          <DarkButton toggleScroll={() => setScrolled(window.scrollY > 50)} handleNavLinkClick={handleNavLinkClick}></DarkButton>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
