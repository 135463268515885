import React, { useState } from 'react';
import DoctorList from '../components/DoctorList';
import '../assets/css/DoctoresPage/styles.css';

const DoctoresSection = () => {
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);

  const handleSpecialtySelect = (specialty) => {
    setSelectedSpecialty(specialty);
  };

  return (
    <main className="doctor-section">
{/* SVG  */}
      <div className='doctor-section-content'>
        <h2>Selecciona una especialidad:</h2>
        <div className="button-group">
          <button 
            className={`button-menu-doctor ${selectedSpecialty === 'Coloproctología' ? 'active' : ''}`} 
            onClick={() => handleSpecialtySelect('Coloproctología')}
          >
            Coloproctología
          </button>
          <button 
            className={`button-menu-doctor ${selectedSpecialty === 'Gastroenterología' ? 'active' : ''}`} 
            onClick={() => handleSpecialtySelect('Gastroenterología')}
          >
            Gastroenterología
          </button>
          <button 
            className={`button-menu-doctor ${selectedSpecialty === 'Psicologia' ? 'active' : ''}`} 
            onClick={() => handleSpecialtySelect('Psicologia')}
          >
            Psicología
          </button>
          
          {/* Botón para mostrar todos */}
          <button 
            className={`button-menu-doctor ${!selectedSpecialty ? 'active' : ''}`} 
            onClick={() => setSelectedSpecialty(null)}
          >
            Mostrar Todos
          </button>
        </div>
        
        {/* Lista de Doctores */}
        <DoctorList specialtyToShow={selectedSpecialty} />
      </div>
    <div className="job-section bg-white z-0 overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title" data-aos="fade-up">
              <p>¿Buscas empleo?</p>
              <h2 className="title">
                <span>Unete a </span> nuesto equipo!
              </h2>
              <p>Buscamos </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-xl-8">
            <div className="row icon-box-style text-start" data-aos="fade-up" data-aos-duration="1100">
              {[
                {
                  icon: 'bi bi-heart-pulse',
                  title: 'Médicos Especialistas',
                  text: 'Lorem ipsum dolor sit amet, consect adipisicing elit vero. Donec ultri sollicitudin lacus',
                },
                {
                  icon: 'bi bi-heart-pulse',
                  title: 'Anesteciologos',
                  text: 'Lorem ipsum dolor sit amet, consect adipisicing elit vero. Donec ultri sollicitudin lacus',
                },
                {
                  icon: 'bi bi-heart-pulse',
                  title: 'Enfermeros',
                  text: 'Lorem ipsum dolor sit amet, consect adipisicing elit vero. Donec ultri sollicitudin lacus',
                },
                {
                  icon: 'bi bi-heart-pulse',
                  title: 'Cardiologos',
                  text: 'Lorem ipsum dolor sit amet, consect adipisicing elit vero. Donec ultri sollicitudin lacus',
                },
                {
                  icon: 'bi bi-heart-pulse',
                  title: 'Médico Internista',
                  text: 'Lorem ipsum dolor sit amet, consect adipisicing elit vero. Donec ultri sollicitudin lacus',
                },
                {
                  icon: 'bi bi-heart-pulse',
                  title: 'Gastroenterologos',
                  text: 'Lorem ipsum dolor sit amet, consect adipisicing elit vero. Donec ultri sollicitudin lacus',
                },
              ].map((box, index) => (
                <div className="col-md-6" key={index}>
                  <div className="icon-box-item">
                    <div className="icon">
                      <i className={box.icon}></i>
                    </div>
                    <div className="content">
                      <h5 className="title">{box.title}</h5>
                      <p>{box.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="thumb" data-aos="fade-left" data-aos-duration="1500">
          <img src="https://hope.jamstacktemplates.dev/img/photos/doctor-01.png" alt="hope" />
        </div>
      </div>
    </div>
    <div className="job-area ">
      <div className="job-form-style1">
        <div className="container">
          <div className="row p-lg-5">
            <div className="col-12">
              <div className="job-form">
                <div className="section-title" data-aos="fade-up">
                  {/* <p>Envia tu CV</p> */}
                  <h2 className="title mb-5">
                    <span>Envianos tu CV</span>
                  </h2>
                </div>
                <form method="POST">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Escribe tu nombre aquí..."
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="tucorreo@gmail.com"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="tel"
                          name="phone"
                          placeholder="Telefono"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group datepicker-group">
                        <label htmlFor="datepicker" className="form-label icon icofont-calendar">
                          <input
                            className="form-control"
                            id="datepicker"
                            type="date"
                            name="date"
                            placeholder="Date"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="7"
                          placeholder="Escribe tu mensaje aquí..."
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-0">
                        <button className="btn btn-primary mt-5" type="submit">
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </main>

    
  );
};

export default DoctoresSection;
