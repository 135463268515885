// components/RoutesConfig.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import GastroenterologiaPage from '../pages/Gastroenterologia';
import ColoproctologiaPage from '../pages/Coloproctologia';
import LaparoscopiaPage from '../pages/Laparoscopia';
import UnidadesPage from '../pages/UnidadesMedicci';
import DoctoresPage from '../pages/DoctoresPage';
import CurriculumPage from '../pages/Curriculum';
import NotFoundSection from '../components/404Section';
import AvisoPrivPage from '../pages/AvisoPriv';
import EndoscopíaPage from '../pages/Endoscopía';
import Redirector from '../components/Redirector';
import Sitemap from '../components/Sitemap';
import CalculatorIMC from '../components/extras/CalculatorIMC';
import pagesData from '../assets/data/PagesData';
import gastroenterologiaPagesData from '../assets/data/GastroenterologiaPagesData';
import PreciosPage from '../pages/Precios';
import ContactPage from '../pages/Contact';
import StaticPage from './pages/StaticPage.jsx';

const RoutesConfig = () => {
  return (
    <Routes>
      <Route 
        path="/" 
        element={<HomePage 
          title="Unidad de Gastroenterología - Inicio"
          description="Unidad de Gastroenterología."
          keywords="Manga gástrica, Home, Página principal"
          canonical="https://unidad-gastroenterologia.com/"
        />} 
      />
      <Route 
        path="/Laparoscopia"
        element={<LaparoscopiaPage 
          pageData={pagesData['Laparoscopia']}
        />} 
      />
      <Route 
        path="/Laparoscopia/Colecistectomia"
        element={<LaparoscopiaPage 
          pageData={pagesData['Colecistectomia']}
        />} 
      />
      <Route 
        path="/Laparoscopia/Funduplicatura"
        element={<LaparoscopiaPage 
          pageData={pagesData['Funduplicatura']}
        />}
      />
      <Route 
        path="/Laparoscopia/Apendicectomia"
        element={<LaparoscopiaPage 
          pageData={pagesData['Apendicectomia']}
        />}
      />
      <Route 
        path="/Gastroenterologia"
        element={<GastroenterologiaPage
          pageData={gastroenterologiaPagesData['Gastroenterologia']}
        />}
      />
      <Route 
        path="/Clinica-de-Coloproctologia"	
        element={<ColoproctologiaPage pageData={pagesData['Coloproctologia']}/>}
      />
      <Route
        path="/Endoscopia-Digestiva"
        element={<EndoscopíaPage
          pageData={pagesData['Endoscopia']}
        />}
      />
      <Route path="/pages/:pageName" element={<StaticPage />} />
      <Route path="/Unidades-Medicas" element={<UnidadesPage />} />
      <Route path="/Doctores" element={<DoctoresPage />} />
      <Route path="/ubicaciones" element={<ContactPage />} />
      <Route path="/Enfermedades-de-Esofago" element={<ContactPage />} />
      <Route path="/Enfermedades-de-Estomago" element={<ContactPage />} />
      <Route path="/Enfermedades-de-Higado-y-Vias-Biliares" element={<ContactPage />} />
      <Route path="/Doctor-Juan-Antonio" element={<CurriculumPage />} />
      <Route path="/Aviso-Privacidad" element={<AvisoPrivPage />} />
      <Route path="/Calculadora-IMC" element={<CalculatorIMC />} />
      <Route path="/404" element={<NotFoundSection />} />
      <Route path="/sitemap.xml" element={<Sitemap />} />
      <Route path="/precios" element={<PreciosPage
        title="Unidad de Gastroenterología - Precios"
        description="Unidad de Gastroenterología."
        keywords="Manga gástrica, Home, Página principal de precios"
        canonical="https://unidad-gastroenterologia.com/Precos"
      />} />
      <Route path="/pacientes" element={<PreciosPage
      title="Unidad de Gastroenterología - Pacientes"
      description="Unidad de Gastroenterología."
      keywords="Manga gástrica, Home, Página principal de precios"
      canonical="https://unidad-gastroenterologia.com/pacientes"
    />} />
      <Route path="*" element={<Redirector />} />
    </Routes>
  );
};

export default RoutesConfig;
