import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/SliderUnidades/styles.css';
import SliderUnidades from './SliderUnidades';
// import { Link } from 'react-router-dom'


function SectionUnidadesMedicas() {

  return (
    <section id="SectionUnidadesMedicas" className="section-unidades-medicas">
      <Container>
        <Row className="justify-content-between gy-5">
          <Col lg={6} order={2} className="order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-center content-col">
            <h2 data-aos="fade-up">Conoce todas nuestras <span className="banner-span-green">unidades</span> médicas <span className='text-primary'></span></h2>
            {/* <p data-aos="fade-up" data-aos-delay="100" className='text-primary'>
            Conoce nuestras unidades y contacta con nosotros para obtener más información.
            </p> */}
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
            </div>
          </Col>
        </Row>
      </Container>
      <SliderUnidades />
    </section>
  );
}

export default SectionUnidadesMedicas;
