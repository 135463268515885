import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import ServiceList from '../components/ServiceList.jsx';

const PreciosPage = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      <section className="unidades-section">
        <Container fluid>
          <div className="my-5">
            <div className="my-5">
              <hr />
              <div className='doctor-section-content'>

                <div>
                  <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
                  <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
                  <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
                </div>
                <ServiceList serviceToShow={selectedService} moduleToShow="Coloproctología" />
                <ServiceList serviceToShow={selectedService} moduleToShow="Gastroenterología" />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};



export default PreciosPage;
