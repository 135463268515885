import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import ServiceList from '../components/ServiceList.jsx';
import DoctorList from '../components/DoctorList';

const EndoscopíaPage = ({ pageData }) => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section style={{ backgroundImage: `url(${pageData.image})` }} className="unidades-section">
        <Container>
            <div className="my-5">
                <div className="my-5">
                <h1>{pageData.title}</h1>
                <hr />
                <Row className='p-lg-5 d-flex align-items-center'>
                    <Col className='p-lg-5' md={6}>
                    <div className="info-item d-flex align-items-center">
                        <div>
                        <p>Método invasivo de diagnóstico y tratamiento intervencionista de enfermedades del Aparato Digestivo Alto (Esófago, Estomago y Duodeno) o Digestivo Bajo (Colon y Recto) en la que se introduce una fibra óptica a través de la boca (panendoscopia) o del Ano (Colonoscopia).</p>
                        </div>
                    </div>
                    </Col>
                    <Col className='p-5 align-items-center' md={6}>
                    <div className="info-item d-flex justify-content-around">
                        <div>
                        <img className='img-fluid rounded-4' width={500} src="https://unidad-gastroenterologia.com/images/clinica-de-balon-gastrico-header.jpg" alt="Imagen de Endoscopia Digestiva" />
                        </div>
                    </div>
                    </Col>
                    </Row>
                </div>
                <hr />
            </div>
        </Container>
        <Container fluid>
        <div className='doctor-section-content'>
            
                <h2>Endoscopia Gastrointestinal</h2>
                <p>La endoscopía digestiva es una herramienta valiosa en el diagnostico y tratamiento de enfermedades gastrointestinales.</p>
                <p>La tecnología en endoscopia digestiva ha avanzado significativamente en los ultimos años, permitiendo realizar diagnósticos mas precisos a través de la cromoendoscopia y tratamientos con innovaciones relevantes, como el uso de: radiofrecuencia, argon plasma, láser CO2 y ultimamente la introduccion de <span className='fw-semibold'>Sutura Endoscopica.</span></p>
              </div>
          <div className="my-5">
            <div className="my-5">
              <div className='doctor-section-content'>

                <div>
                  <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
                  <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
                  <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
                </div>
                <ServiceList serviceToShow={selectedService} moduleToShow="Endoscopia Digestiva" />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className='p-5'>
        {/* Section de Equipo Médico Clínica de Endoscopia  */}
        <div className='doctor-section-content'>
            <h2>Conoce a nuestro Equipo Médico:</h2>
            <h4>Contamos con un Equipo de Endoscopistas certificados y altamente capacitados en procedimientos endoscopicos complejos y de avanzada tecnología, Nuestos Médicos estan comprometidos a mantenerse al dia con las ultimas investigaciones
            y técnicas en el campo de la Endoscopia, garantizando así tratamientos basados en evidencias y protocolos actualizados :</h4>
            <DoctorList specialtyToShow={'Endoscopia Digestiva'} />
        </div>
      </section>
      <section className='p-5'>
        {/* Section de ENlaces Clínica de Endoscopia  */}
        <div className='doctor-section-content'>
            <Container>
            <h2>Procedimientos Terapéuticos Endoscópicos que se Realizan en Medicci - Unidad de Gastroenterología y Endoscopía Digestiva.</h2>
            <p>En Medicci, llevamos la Endoscopia a un nuevo nivel. Nuestos tratamientos Endoscópicos de alta tecnología estan diseñados para ofrecerte una experiencia médica de alta calidad, combinando la precisión de los dispositivos con la capacidad de un equipo médico de especialistas comprometidos con tu bienestar.</p>
            <Row>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                        <img src="https://unidad-gastroenterologia.com/images/stretta-o-gastroplastia-esofago-gastrica.png" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a className='mt-4' href="/inicio">Stretta o Gastroplastia Esofago Gastrica Con Radiofrecuencia</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia"> 
                    <img src="https://unidad-gastroenterologia.com/images/tratamiento-sangrado-de-ulcera-gastrica.jpg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a className='mt-4' href="/inicio">Tratamiento Sangrado de Ulcera Gastrica</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                    <img src="https://unidad-gastroenterologia.com/images/dilatacion-esofagica.jpg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a href="/inicio">Dilatación Esofagica</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                    <img src="https://unidad-gastroenterologia.com/images/ligadura-de-varices-esofagicas.jpg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a href="/inicio">Ligadura de Varices Esofagicas</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                    <img src="https://unidad-gastroenterologia.com/images/balon-intragastrico-ajustable-spatz.jpeg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a href="http://www.balongastricocdmx.com/" target="_blank" rel="noopener noreferrer" aria-label="Balon Intragastrico Ajustable Spatz 3 de 1 año (abre en una nueva pestaña)">Balon Intragastrico Ajustable Spatz 3 de 1 año</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                    <img src="https://unidad-gastroenterologia.com/images/polipectomia-con-asa.jpg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a href="/inicio">Polipéctomias y Mucosectomias Endoscopicas</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                    <img src="https://unidad-gastroenterologia.com/images/manga-gastrica-endoscopica.jpg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a href="http://mangagastricaendoscopica.mx/" target="_blank" rel="noopener noreferrer" aria-label="Manga Gástrica Endoscópica (abre en una nueva pestaña)">Manga Gástrica Endoscópica</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                    <img src="https://unidad-gastroenterologia.com/images/tratamiento-endoscopico-con-argon-plasma.jpg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a href="/inicio">Tratamiento Endoscópico con Argon Plasma</a>
                    </div>
                </Col>
                <Col md={4} className='mt-4'>
                    <div className="Cards-Endoscopia">
                    <img src="https://unidad-gastroenterologia.com/images/ablacion-de-esofago-de-barret-con-radiofrecuencia.jpg" alt="Icono de Procedimiento Endoscopia Digestiva" />
                        <a href="/inicio">Ablación de Esófago de Barret con Radiofrecuencia</a>
                    </div>
                </Col>

            </Row>
            </Container>
        </div>
      </section>
    </>
  );
};



export default EndoscopíaPage;