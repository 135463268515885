import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './assets/sass/custom.scss';
import './App.css';
import NavBar from './components/NavBar';
import RoutesConfig  from './components/RoutesConfig';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { locations } from './components/extras/Direcciones';
import BotonWsp from './components/extras/BotonWsp';
import ContactSection from './components/Contact';

const whatsappNumber = locations.location1.whatsapp;
const phoneNumber = locations.location1.phone;

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <HelmetProvider>
        <div className="App">
          <NavBar />
          <RoutesConfig />
          <ContactSection />
          <Footer />
          <BotonWsp whatsappNumber={whatsappNumber} phoneNumber={phoneNumber} />
        </div>
      </HelmetProvider>
    </Router>
  );
}

export default App;
