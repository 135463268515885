import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DropdownMenu = ({ title, items, className, handleNavLinkClick }) => {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);
  const handleItemClick = () => {
    setShow(false);
    handleNavLinkClick(); // Cierra el Navbar al hacer clic en un elemento del dropdown
  };

  return (
    <Dropdown
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      show={show}
    >
      <Dropdown.Toggle id="dropdown-basic" className={className} as="div">
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu className="custom-dropdown-menu">
        {items.map((item, index) => {
          if (item.target === '_blank') {
            // Enlace externo con target="_blank"
            return (
              <Dropdown.Item
                key={index}
                href={item.to}
                target={item.target}
                rel="noopener noreferrer"
                className="custom-dropdown-item"
                onClick={handleItemClick}
              >
                {item.label}
              </Dropdown.Item>
            );
          } else {
            // Enlace interno con Link
            return (
              <Dropdown.Item
                as={Link}
                key={index}
                to={item.to}
                className="custom-dropdown-item"
                onClick={handleItemClick}
              >
                {item.label}
              </Dropdown.Item>
            );
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
