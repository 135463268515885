import React from 'react';
import PropTypes from 'prop-types';

const ServiceCard = ({ id, name, title, service, photoUrl, group, link, onClick }) => (
  <div className={`card-doctor doctor-card ${group}`} onClick={onClick}>
    <img src={photoUrl} className="card-img-top img-fluid" alt={`Imagen de ${name}`} />
    <div className="card-body">
      <h5 className="card-title text-uppercase">{name}</h5>
      <p className="card-text">{title}</p>
      <p className="card-text"><small className="text-muted">{service}</small></p>
    </div>
  </div>
);

ServiceCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  service: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  group: PropTypes.string,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,  // Add onClick prop
};

export default ServiceCard;
